.imge-body{
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.158);
    /* z-index: 999;
    display: flex; 
    align-items: center;
    justify-content: center; */
}
.imge-body img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
}
.ReactModal__Overlay {
    z-index: 9999;
    background-color: rgb(0 0 0 / 60%) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-button{
    color: white;
    top:0px;
    right:5px;
    font-size: 1.3rem;
    position: absolute;
}
.ReactModal__Content {
    position: absolute;
    inset: auto !important;
    border: 1px solid rgb(204, 204, 204);
    background: rgba(2, 2, 2, 0.349) !important;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 30px;
}

