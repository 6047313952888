/* LoadingScreen.css */
.loading-screen {
    position:fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height:100vh;
    background:var(--dgi-theme-primary); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999; 
  }
  

  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-screen p {
    margin-top: 10px;
  }
  .logos {
    width: 200px;
    height: 200px;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
  }
  
  .logos span{
    font-size:6rem;
    text-shadow: 0px 2px #f3f3f3;
    font-weight: bold;
    color: red;
    animation: animate 6s linear infinite;
  }
  .logos p{
    font-size:2rem;
    color: white;
    font-weight: bold;
    margin-top:30px;
  }
  .loading-screen2 .logos2{
    position:fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background:rgba(2, 38, 67);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logos svg {
    font-family: 'Russo One', sans-serif;
    position: absolute; 
    width:50%; height: 50%;
  }
  .logos svg text {
    text-transform: uppercase;
    animation: stroke 5s infinite alternate;
    stroke-width: 2;
    stroke: #f30404;
    font-size: 140px;
  }
  @keyframes stroke {
    0%   {
      fill: rgba(0,0,0,); stroke: rgba(54,95,160,1);
      stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
    }
    70%  {fill: rgba(0,0,0,0); stroke: rgba(54,95,160,1); }
    80%  {fill: rgba(0,0,0,0); stroke: rgba(54,95,160,1); stroke-width: 3; }
    100% {
      fill: rgba(0,0,0,0); stroke: rgba(54,95,160,0); 
      stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
    }
  }
  
  @keyframes animate{
    0%,10%,100%{
        width: 0;
    }
    70%,90%{
        width: 100%;
    }
  }