
#progress-container{
height:4px;
width:100%;
background:transparent;
position:fixed;
top:0;
left:0;
right:0; 
z-index:99999;
}